jQuery( document ).ready(function($) {

  //accordion
  $('.accordion-title').on('click', function() {
    $(this).siblings('.accordion-content').slideToggle();
  })

  // Animations
  AOS.init({
    duration: 1000,
    once: true,
    offset: 0,
  });

  // Form
  $("#black-sky-creative-newsletter").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        // Do things on successful submission.
        $('#black-sky-creative-newsletter, .form-note.error').hide()
        $('.form-note.success').show()
      },
      error: function (err) {
        // Do things when submission has an error.
        $('.form-note.error').show()
      }
    });
  });

  $('body').on('change', '.mailing-list-id', function () {
    $( "#ts-for-"+$(this).attr('id')).prop( "checked", $(this).is(':checked') );
  });

});